<template>
  <div v-if="event">
    <v-toolbar Zdark elevation="0">
      <v-spacer />
      <v-btn text color="accent" @click="reprocess">
        <v-icon small class="me-1">fa-cogs</v-icon>
        Reprocess
      </v-btn>
    </v-toolbar>
    <v-container class="info-container grey lighten-5 py-0">
      <v-row>
        <v-col cols="12" sm="6">
          <h3>General Info</h3>
          <div class="label">ID</div>
          <div class="value"><pre>{{event.id}}</pre></div>
          
          <div class="label">Name</div>
          <div class="value">{{event.name}}</div>
          
          <div class="label">Contact</div>
          <div class="value">{{event.email}} <v-btn icon color="accent" small @click="$helpers.copyToClipboard(event.email, $toast)"><v-icon small class="mr-2">fa-copy</v-icon></v-btn></div>

          <div class="label">Opens (included)</div>
          <div class="value">
            {{event.from | localDate("L", /* inOriginalTimeZone */ true)}}
            <v-chip  v-if="$helpers.isActiveEvent(event)" outlined color="accent darken-0" class="white--text">Active now!</v-chip>
            <v-chip  v-else-if="$helpers.isFutureEvent(event)" outlined color="accent darken-0" class="white--text">Opens in {{$helpers.getStartDurationForEvent(event) | duration(true)}}</v-chip>
          </div>

          <div class="label">Closes (included)</div>
          <div class="value">
            {{event.till | localDate("L", /* inOriginalTimeZone */ true)}}
            <v-chip v-if="$helpers.isActiveEvent(event)" outlined color="accent darken-0" class="white--text">Closes in {{$helpers.getEndDurationForEvent(event) | duration(true)}}</v-chip>
            <v-chip v-else-if="$helpers.isFutureEvent(event)" outlined color="accent darken-0" class="white--text">Duration {{Math.round($helpers.getDurationForEvent(event).length('days'))}} days</v-chip>
          </div>

          <div class="label">Duration</div>
          <div class="value">
            {{Math.round($helpers.getDurationForEvent(event).length('days'))}} days
          </div>

          <div class="label">Template</div>
          <div class="value">
            <div v-if="eventUtil.hasMeta('cfg.template.id')">
              <v-chip color="accent" outlined>{{ eventUtil.getMetaStringValue('cfg.template.name') }}</v-chip>
              <v-btn v-if="eventUtil.hideAdvancedEventOptions()" text color="primary" @click="unlock">Unlock</v-btn>
            </div>            
          </div>

        </v-col>
        <v-col cols="12" sm="6">
          <h3>License Info</h3>
          <div class="label">License</div>
          <div class="value">
            {{licenseInfo && licenseInfo.license | license }}
            <v-btn color="accent" outlined small class="ms-2" @click="showLicenseDialog=true">
              Apply
            </v-btn>
          </div>
          
          <div class="label">Support plan</div>
          <div class="value">{{eventUtil.getSupportPlan() || '-'}}</div>
          
          <div class="label">Purchased users</div>
          <div class="value">{{licenseInfo && licenseInfo.max_capacity}} (invoiced: {{ eventUtil.getMetaIntValue('invoiced.count') || '-' }}) </div>
          
          <div class="label">Joined users</div>
          <div class="value">{{licenseInfo && licenseInfo.current_capacity}}</div>
          
          <div class="label">Extended duration</div>
          <div class="value">
            {{ (licenseInfo && licenseInfo.extended_duration) || 'No' }}
            <v-btn color="accent" text small class="ms-2" @click="showDurationDialog=true">
              Apply
            </v-btn>
          </div>
          
          <div class="label">License valid?</div>
          <div class="value">{{ licenseInfo && licenseInfo.valid }}</div>
                    
        </v-col>
      </v-row>
    </v-container>
    <EventStatusCard :event="event" :joinUrl="joinUrl"/>
    <v-alert v-if="event.validation && event.validation.meta.status == 'ERROR'" tile type="error" class="mb-0">
      <p>There are one or more validation issues. Please resolve these before going live:</p>
      <ul class="font-weight-bold"><li v-for="(item,idx) in event.validation.data" :key="idx">{{item.msg}}</li></ul>
    </v-alert>
    
    <div class="pa-4">
      <h3>Meta</h3>
      <v-data-table
        :headers="[ { text: 'Key', value: 'key' }, { text: 'Value (String)', value: 'val_str' }, { text: 'Value (Int)', value: 'val_int' }, { text: 'Value (Date)', value: 'val_dto' }, { text: 'Value (Double)', value: 'val_dbl' }  ]"
        :items="event.meta"
        :items-per-page="50"
        class="elevation-1 mb-8"
        />
      <h3>Logs</h3>
      <v-data-table
        v-if="logs"
        :headers="[ { text: 'Time', value: 'timestamp' }, { text: 'Level', value: 'level' }, { text: 'Tag', value: 'tag' }, { text: 'Message', value: 'msg' }, { text: 'By Admin?', value: 'admin' },  ]"
        :items="logs.data"
        :items-per-page="50"
        class="elevation-1"
        />
    </div>

    <multi-step-dialog v-model="showLicenseDialog" title="Apply License" confirm-label="Apply" single-step @confirm="applyLicense">
      <v-card-text>
        <v-switch v-model="licenseRequest.invoiced" label="Invoice created in MoneyBird" />

        <ToggleButtonInput
          v-model="licenseRequest.license"
          label="License"
          :items="[{type:null, text: 'None'}, {type:'BASIC', text: 'Starter'}, {type:'PRO', text: 'Pro'}, {type:'ENTERPRISE', text: 'Enterprise'}]"
          />

        <v-text-field v-model.number="licenseRequest.quantity" type="number" min="10" label="Number of participants" />

        <ToggleButtonInput
          v-model="licenseRequest.supportPlan"
          label="Support plan"
          :items="[{type:null, text: 'Self-service'}, {type:'setup-service', text: 'Setup Service'}, {type:'full-service', text: 'Full Service'}]"
          />
      </v-card-text>
    </multi-step-dialog>

    <multi-step-dialog v-model="showDurationDialog" title="Apply Extended Duration" confirm-label="Apply" single-step @confirm="applyExtendedDuration">
      <v-card-text>

        <ToggleButtonInput
          v-model="durationRequest.duration"
          label="Duration"
          :items="[{type:null, text: 'Default (45 days)'}, {type:'1q', text: 'Quarter (100 days)'}, {type:'2q', text: 'Half a year'}, {type:'1y', text: 'Year'}]"
          />

      </v-card-text>
    </multi-step-dialog>

  </div>
</template>

<script>
import store from "@/store";
import { mapGetters } from "vuex";
import adminService from "@/services/adminService";
import eventManagerService from "@/services/eventManagerService";
import { EventBus } from '@/plugins/eventbus.js';
import ToggleButtonInput from "@/components/ToggleButtonInput";
import MultiStepDialog from '@/components/generic/MultiStepDialog.vue';
import EventUtil from "@/util/eventUtil";
import EventGrid from "@/components/EventGrid.vue";
import EventStatusCard from '@/views/eventmanager/_EventStatusCard.vue'

export default {
  name: "AdminEventDetails",
  components: {
    EventGrid,
    EventStatusCard,
    MultiStepDialog,
    ToggleButtonInput,
  },
  props: {
    event: Object,
    tenant: Object,
  },
  data() {
    return {
      error: null,
      licenseInfo: null,
      joinInfo: null,
      logs: null,
      showLicenseDialog: false,
      showDurationDialog: false,
      licenseRequest: {},
      durationRequest: {},
    };
  },
  async mounted() {
    await this.loadData();
    
    EventBus.$on('login-state-change', async user => {
      await this.loadData();
    });
  },
  methods: {

    async loadData() {
      console.log('Loading event')
      if (this.user && this.event) {
        let id = this.$route.params.id;
        this.licenseInfo = (await eventManagerService.getLicenseStatus(id)).data;
        //this.event = (await eventManagerService.get(this.$route.params.id)).data;
        this.joinInfo = { join_url: this.event && this.event.join_url };
        if (this.event.verification_method === 'ALLOWLIST') {
          this.joinInfo = (await eventManagerService.getJoinInfo(id)).data;
        }
        console.log('this.licenseInfo', this.licenseInfo);
        this.logs = (await eventManagerService.getLogs(id)).data;
        this.licenseRequest = {
          invoiced: this.eventUtil.hasMeta('invoiced.count'),
          license: this.licenseInfo.license,
          quantity: this.eventUtil.getMetaIntValue('invoiced.count') || 0,
          supportPlan: this.eventUtil.getSupportPlan(),
        };
      }
    },
    async applyLicense() {
      console.log('Apply license now', this.licenseRequest);
      var result = (await adminService.applyEventLicense(this.event.id, this.licenseRequest.license, this.licenseRequest.quantity, this.licenseRequest.supportPlan, this.licenseRequest.invoiced)).data;
      if (result.status == 'OK') {
        this.licenseRequest = {};
      }
      this.eventUtil.refresh();
      this.loadData();
    },
    async applyExtendedDuration() {
      console.log('Apply license now', this.durationRequest);
      var result = (await adminService.applyExtendedDuration(this.event.id, this.durationRequest.duration)).data;
      if (result.status == 'OK') {
        this.durationRequest = {};
      }
      this.eventUtil.refresh();
      this.loadData();
    },
    async reprocess() {
      if (!confirm('Are you sure you want to reprocess the results for this event?')) {
        return;
      }
      var result = (await adminService.reprocessEvent(this.event.id)).data;
      if (result.status === 'ONGOING') {
        window.location.href = result.msg;
        return;
      }
      this.eventUtil.refresh();
      this.loadData();
    },
    async unlock() {
      if (!confirm('Are you sure you want to unlock advanced edit features for this event?')) {
        return;
      }
      await adminService.unlockFullCustomization(this.event.id);
      this.eventUtil.refresh();
      this.loadData();
    },

  },
  computed: {
    eventUtil() {
      return new EventUtil(this, this.event, this.race);
    },
    joinUrl() {
      if (this.event == null) {
        return null;
      }
      return this.joinInfo ? this.joinInfo.join_url : this.event.join_url;
    },
    ...mapGetters({
      user: "user"
    })
  },
  watch: {
    event() {
      if (this.licenseInfo == null) {
        this.loadData();
      }
    },
  }

};
</script>
<style lang="scss">

.search-box {
  max-width: 350px;
  float: right;
}
</style>

